import React, { Component } from "react";
import { Helmet } from "react-helmet";
import $ from "jquery";
import { Container, Row, Col, Carousel } from "react-bootstrap";
import "../Style/ConnaisToi.scss";

//import des images
import vulveCouche1 from "../Images/vulve-c1.png";
import vulveCouche2 from "../Images/vulve-c2.png";
import vulveCouche3 from "../Images/vulve-c3.png";
import vulveCouche4 from "../Images/vulve-c4.png";
import vulveCouche5 from "../Images/vulve-c5.png";
import vulveCouche6 from "../Images/vulve-c6.png";
import vulveCouche7 from "../Images/vulve-c7.png";
import vulveCouche8 from "../Images/vulve-c8.png";
import vulveCouche9 from "../Images/vulve-c9.png";
import vulveComplette from "../Images/vulve-complette.png";
import vivaLaVulva from "../Images/viva-la-vulvaXnana.png";
import frise from "../Images/Frise.png";
import mains from "../Images/hand.png";
import draw5 from "../Images/draw5.svg";
import slide1 from "../Images/carousel-img1.png";
import slide2 from "../Images/carousel-img2.png";
import slide3 from "../Images/carousel-img3.png";
import slide4 from "../Images/carousel-img4.png";
import slide5 from "../Images/carousel-img5.png";
import cupJ5 from "../Images/cup-jour1.svg";
import cupJ4 from "../Images/cup-jour2.svg";
import cupJ3 from "../Images/cup-jour3.svg";
import cupJ2 from "../Images/cup-jour4.svg";
import cupJ1 from "../Images/cup-jour5.svg";
import pictoTdf from "../Images/picto-svg-tdf.svg";
import draw6 from "../Images/draw6.png";
import draw7 from "../Images/draw7.svg";

// attr alt img
const altDessinVulve =
  "dessin d'une vulve réalisé par la fondatrice du compte Instagram '@mydearvagina' Laura Stromboni-Couzy'";
const titleLabel = "clique pour découvrir à quoi je ressemble !";
const altExposition = "Viva La Vulva";
const altImgSlide = "Viva La Vulva";
const altCup = "cup";
const altFrise = "phases menstruations";
const altPictoTdf = "clit";

class ConnaisToi extends Component {
  componentDidMount() {
    // class active link header
    $("#svg-link2").addClass("component-active");

    wrappersize();
    window.onresize = wrappersize;
    // gestion de la taille .wrapper selon image container 6
    function wrappersize() {
      var wrapperHeight = $(".wrapper").outerHeight();
      var coeff = "1.59998994";
      var nbContainer = "500vw";
      $(".wrapper").css(
        "width",
        "calc( " +
        nbContainer +
        " + (" +
        coeff +
        " * " +
        wrapperHeight +
        "px)) "
      );
    }

    // anim click label
    $("p").click(function () {
      var label = $(this).attr("data-label");
      var images = $(this).attr("data-images");
      $("#svg-" + label + "").toggleClass("svg-active");
      $("#image-" + images + "").addClass("image-shake");
      setTimeout(deleteClassShake, 900);
    });

    // supprimer la class shake apres 900ms
    function deleteClassShake() {
      $(".colImg img ").removeClass("image-shake");
    }

    // anim click label clitoris
    $("#label-clitoris").click(function () {
      $(".label-clitoris").toggleClass("label-clitoris-visible");
    });

    // anim click label vagin
    $("#label-vagin").click(function () {
      $(".label-vagin").toggleClass("label-vagin-visible");
    });

    // changement clitoris => vulve
    $("#label-discover-clito").click(function () {
      $("#row-images-screen, #row-label-screen").removeClass("is-visible");
      $("#row-images-screen, #row-label-screen").addClass("is-invisible");
      $("#row-images-screen-2, #row-label-screen-2").removeClass(
        "is-invisible"
      );
      $("#row-images-screen-2, #row-label-screen-2").addClass("is-visible");
      $("#anatom-clito").addClass("is-visible");
      $("#anatom-clito").removeClass("is-invisible");
      $("#anatom-vulve").addClass("is-invisible");
      $("#anatom-vulve").removeClass("is-visible");
    });

    // changement vulve => clitoris
    $("#label-discover-vagin").click(function () {
      $("#row-images-screen, #row-label-screen").removeClass("is-invisible");
      $("#row-images-screen, #row-label-screen").addClass("is-visible");
      $("#row-images-screen-2, #row-label-screen-2").removeClass("is-visible");
      $("#row-images-screen-2, #row-label-screen-2").addClass("is-invisible");
      $("#anatom-clito").addClass("is-invisible");
      $("#anatom-clito").removeClass("is-visible");
      $("#anatom-vulve").addClass("is-visible");
      $("#anatom-vulve").removeClass("is-invisible");
    });
  }

  render() {
    return (
      <main id="main-ConnaisToi">
        <Helmet>
          <title>My Dear Vagina - Connais-toi</title>
        </Helmet>
        <div className="outer-wrapper">
          <div className="wrapper">
            <div
              id="slide"
              className="d-flex flex-column align-items-center justify-content-center"
            >
              <img
                src={draw6}
                alt={altPictoTdf}
                className="picto-tdf-abs top-70 translate-20 align-self-start"
              />
              <Container id="container1" className="">
                {/*  DEBUT SECTION SCREEN */}
                <Row
                  id="row-images-srceen"
                  md={12}
                  className=" row-label d-flex flex-row justify-content-sm-center justify-content-md-start mt-sm-5 is-visible"
                >
                  <Col
                    sm={{ span: 8, offset: 1 }}
                    lg={{ span: 10, offset: 1 }}
                    xl={{ span: 10, offset: 2 }}
                    className="colImg p-0"
                  >
                    <img
                      src={vulveCouche1}
                      alt={altDessinVulve}
                      id="image-c1"
                    />
                    <img
                      src={vulveCouche2}
                      alt={altDessinVulve}
                      id="image-c2"
                    />
                    <img
                      src={vulveCouche3}
                      alt={altDessinVulve}
                      id="image-c3"
                    />
                    <img
                      src={vulveCouche4}
                      alt={altDessinVulve}
                      id="image-c4"
                    />
                    <img
                      src={vulveCouche5}
                      alt={altDessinVulve}
                      id="image-c5"
                    />
                    <img
                      src={vulveCouche6}
                      alt={altDessinVulve}
                      id="image-c6"
                    />
                    <img
                      src={vulveCouche7}
                      alt={altDessinVulve}
                      id="image-c7"
                    />
                    <img
                      src={vulveCouche8}
                      alt={altDessinVulve}
                      id="image-c8"
                    />
                    <img
                      src={vulveCouche9}
                      alt={altDessinVulve}
                      id="image-c9"
                    />
                  </Col>
                </Row>
                <Row
                  id="row-label-screen"
                  className="d-flex flex-column align-items-center justify-content-center mt-sm-5 p-0 is-visible"
                >
                  <Col md={9} lg={12} className=" d-flex flex-row">
                    <p
                      className="label"
                      data-images=""
                      data-label="label1"
                      title={titleLabel}
                    >
                      {" "}
                      pubis{" "}
                    </p>
                    <svg
                      className="svg-label1 svg"
                      id="svg-label1"
                      width="120"
                      height="106"
                      viewBox="0 0 144 106"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M1.51086 43.614C22.4612 35.9025 44.5943 38.3527 69.2665 42.8896C93.6763 47.3782 117.334 52.8239 141.011 62.1341"
                        stroke="#2E2828"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M141.613 62.3647C140.509 62.8847 139.404 63.4047 138.299 63.9247"
                        stroke="#2E2828"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M141.927 62.1476C141.131 61.0897 140.334 60.0317 139.538 58.9738"
                        stroke="#2E2828"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </Col>
                  <Col
                    sm={12}
                    md={11}
                    lg={10}
                    className="d-flex flex-row align-items-center justify-content-space-between"
                  >
                    <Col className="d-flex flex-row justify-content-start align-items-start">
                      <p
                        className="label"
                        data-images="c5"
                        data-label="label2"
                        title={titleLabel}
                      >
                        {" "}
                        capuchon du prépuce
                      </p>
                      <svg
                        className="svg-label2 svg"
                        id="svg-label2"
                        width="200"
                        height="247"
                        viewBox="0 0 248 247"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M1.99996 96C63.564 80.2441 103.008 87.9049 142.616 100.586C181.803 113.132 217.136 127.805 243.175 151.342"
                          stroke="#2E2828"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M243.995 152.488C240.38 153.205 236.765 153.922 233.151 154.639"
                          stroke="#2E2828"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M244.528 151.863C243.2 148.242 241.871 144.621 240.543 141"
                          stroke="#2E2828"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </Col>
                    <Col className="d-flex flex-row justify-content-end">
                      <p
                        className="label"
                        data-images="c5"
                        data-label="label3"
                        id="label-clitoris"
                        title={titleLabel}
                      >
                        {" "}
                        clitoris{" "}
                      </p>
                      <p className="label-clitoris" id="label-discover-clito">
                        Découvre à quoi <br />
                        je ressemble
                      </p>
                      <svg
                        className="svg-label3 svg"
                        id="svg-label3"
                        width="244"
                        height="259"
                        viewBox="0 0 244 259"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M242.369 72.1674C195.192 68.5317 156.724 85.6372 115.782 108.274C75.2747 130.671 37.1918 154.542 3.21948 186.063"
                          stroke="#2E2828"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M2.34799 186.852C4.95724 187.268 7.5665 187.684 10.1757 188.1"
                          stroke="#2E2828"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M1.53258 186.597C1.87576 184.065 2.21894 181.532 2.56213 179"
                          stroke="#2E2828"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </Col>
                  </Col>
                  <Col
                    lg={12}
                    className="d-flex flex-row align-items-center justify-content-end"
                  >
                    <p
                      className="label"
                      data-images="c7"
                      data-label="label4"
                      title={titleLabel}
                    >
                      {" "}
                      petites lèvres{" "}
                    </p>
                    <svg
                      className="svg-label4 svg"
                      id="svg-label4"
                      width="299"
                      height="210"
                      viewBox="0 0 299 210"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M296.807 116.978C253.583 87.3745 207.227 82.4807 155.436 81.5207C104.196 80.5709 54.4631 82.3775 4.43742 93.7949"
                        stroke="#2E2828"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M1.99999 93.5435C4.27172 95.3868 6.54345 97.2301 8.81518 99.0734"
                        stroke="#2E2828"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M1.81117 93.5337C4.20335 92.0225 6.59553 90.5112 8.98771 89"
                        stroke="#2E2828"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </Col>
                  <Col>
                    <p
                      className="label"
                      data-images="c4"
                      data-label="label5"
                      title={titleLabel}
                    >
                      {" "}
                      urêtre{" "}
                    </p>
                    <svg
                      className="svg-label5 svg"
                      id="svg-label5"
                      width="300"
                      height="264"
                      viewBox="0 0 350 264"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M2 158C54.4898 116.065 109.064 106.623 169.748 102.037C229.787 97.5008 287.885 96.7078 345.71 108.612"
                        stroke="#2E2828"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M347.183 108.894C344.406 111.478 341.629 114.063 338.851 116.647"
                        stroke="#2E2828"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M346.739 108.754C344.856 105.904 342.973 103.053 341.09 100.202"
                        stroke="#2E2828"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </Col>
                  <Col
                    md={11}
                    lg={10}
                    className="d-flex flex-row justify-content-end"
                  >
                    <p
                      className="label"
                      data-images="c4"
                      data-label="label6"
                      title={titleLabel}
                    >
                      {" "}
                      vagin{" "}
                    </p>
                    <svg
                      className="svg-label6 svg"
                      id="svg-label6"
                      width="211"
                      height="197"
                      viewBox="0 0 211 197"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M209 138C173.968 97.3895 141.169 83.7084 105.326 73.437C69.8639 63.2748 35.9304 56.4467 3.50278 60.2569"
                        stroke="#2E2828"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M1.77923 59.5441C3.67151 61.9982 5.56378 64.4522 7.45606 66.9063"
                        stroke="#2E2828"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M1.64743 59.4997C3.02656 58.2438 4.40569 56.9879 5.78481 55.7319"
                        stroke="#2E2828"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </Col>
                  <Col
                    md={11}
                    lg={10}
                    className="d-flex flex-row justify-content-start"
                  >
                    <p
                      className="label"
                      data-images="c9"
                      data-label="label7"
                      title={titleLabel}
                    >
                      {" "}
                      grandes lèvres{" "}
                    </p>
                    <svg
                      className="svg-label7 svg"
                      id="svg-label7"
                      width="250"
                      height="210"
                      viewBox="0 0 298 210"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M2 116.814C45.2241 87.21 91.5796 82.3162 143.371 81.3562C194.611 80.4064 244.344 82.213 294.369 93.6304"
                        stroke="#2E2828"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M296.807 93.3789C294.535 95.2222 292.263 97.0655 289.992 98.9088"
                        stroke="#2E2828"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M296.996 93.3692C294.604 91.8579 292.211 90.3467 289.819 88.8354"
                        stroke="#2E2828"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </Col>
                  <Col lg={12} className="d-flex flex-row justify-content-end">
                    <p
                      className="label"
                      data-images="c4"
                      data-label="label8"
                      title={titleLabel}
                    >
                      {" "}
                      orifice vaginal{" "}
                    </p>
                    <svg
                      className="svg-label8 svg"
                      id="svg-label8"
                      width="320"
                      height="342"
                      viewBox="0 0 321 342"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M319 247C257.101 251.429 206.478 228.643 152.565 198.562C99.2259 168.802 49.0578 137.119 4.22817 95.4062"
                        stroke="#2E2828"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M3.07829 94.3609C6.50023 93.8334 9.92217 93.3059 13.3441 92.7784"
                        stroke="#2E2828"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M3.40296 94.7199C3.87237 98.0533 4.34178 101.387 4.81119 104.72"
                        stroke="#2E2828"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </Col>
                  <Col
                    md={11}
                    lg={10}
                    className="d-flex flex-row justify-content-end"
                  >
                    <p className="label" data-label="label9" title={titleLabel}>
                      {" "}
                      périnée{" "}
                    </p>
                    <svg
                      className="svg-label9 svg"
                      id="svg-label9"
                      width="192"
                      height="150"
                      viewBox="0 0 192 150"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M190 71.0001C160.406 55.818 130.692 56.4299 97.8326 59.9492C65.3231 63.4311 33.976 68.527 3.16743 79.6922"
                        stroke="#2E2828"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M2.38236 79.9663C3.9594 80.945 5.53644 81.9238 7.11347 82.9025"
                        stroke="#2E2828"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M1.92438 79.5794C2.85154 78.0335 3.7787 76.4877 4.70586 74.9418"
                        stroke="#2E2828"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </Col>
                </Row>

                <Row
                  id="row-images-screen-2"
                  md={12}
                  className=" row-label d-flex flex-row justify-content-sm-center justify-content-md-start mt-sm-5 is-invisible"
                >
                  <Col
                    sm={{ span: 8, offset: 1 }}
                    lg={{ span: 10, offset: 1 }}
                    xl={{ span: 10, offset: 2 }}
                    className="colImg p-0"
                  >
                    <img src={vulveCouche1} alt={altDessinVulve} />
                    <img
                      src={vulveCouche2}
                      alt={altDessinVulve}
                      id="image-c2-2"
                    />
                    <img
                      src={vulveCouche3}
                      alt={altDessinVulve}
                      id="image-c3-2"
                    />
                    <img
                      src={vulveCouche4}
                      alt={altDessinVulve}
                      id="image-c4-2"
                    />
                    <img
                      src={vulveCouche5}
                      alt={altDessinVulve}
                      id="image-c5-2"
                    />
                    <img
                      src={vulveCouche6}
                      alt={altDessinVulve}
                      id="image-c6-2"
                    />
                  </Col>
                </Row>
                <Row
                  id="row-label-screen-2"
                  className=" d-flex flex-column align-items-center justify-content-center mt-sm-5 p-0 is-invisible"
                >
                  <Col md={9} lg={12} className=" d-flex flex-row">
                    <p className="label label-hide" title={titleLabel}>
                      {" "}
                      label hide{" "}
                    </p>
                    <svg
                      className="svg-label1 svg"
                      id="svg-label1-2"
                      width="120"
                      height="106"
                      viewBox="0 0 144 106"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M1.51086 43.614C22.4612 35.9025 44.5943 38.3527 69.2665 42.8896C93.6763 47.3782 117.334 52.8239 141.011 62.1341"
                        stroke="#2E2828"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M141.613 62.3647C140.509 62.8847 139.404 63.4047 138.299 63.9247"
                        stroke="#2E2828"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M141.927 62.1476C141.131 61.0897 140.334 60.0317 139.538 58.9738"
                        stroke="#2E2828"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </Col>
                  <Col
                    sm={12}
                    md={11}
                    lg={10}
                    className="d-flex flex-row align-items-center justify-content-space-between"
                  >
                    <Col className="d-flex flex-row justify-content-start align-items-start">
                      <p
                        className="label"
                        data-images="c5-2"
                        data-label="label2-2"
                        title={titleLabel}
                      >
                        {" "}
                        gland du clitoris
                      </p>
                      <svg
                        className="svg-label2-2 svg"
                        id="svg-label2-2"
                        width="200"
                        height="247"
                        viewBox="0 0 248 247"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M1.99996 96C63.564 80.2441 103.008 87.9049 142.616 100.586C181.803 113.132 217.136 127.805 243.175 151.342"
                          stroke="#2E2828"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M243.995 152.488C240.38 153.205 236.765 153.922 233.151 154.639"
                          stroke="#2E2828"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M244.528 151.863C243.2 148.242 241.871 144.621 240.543 141"
                          stroke="#2E2828"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </Col>
                    <Col className="d-flex flex-row justify-content-end">
                      <p
                        className="label"
                        data-images="c1-2"
                        data-label="label3-2"
                        id="label-vagin"
                        title={titleLabel}
                      >
                        {" "}
                        vulve{" "}
                      </p>
                      <p className="label-vagin" id="label-discover-vagin">
                        Découvre à quoi <br />
                        je ressemble
                      </p>
                      <svg
                        className="svg-label3-2 svg"
                        id="svg-label3-2"
                        width="244"
                        height="259"
                        viewBox="0 0 244 259"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M242.369 72.1674C195.192 68.5317 156.724 85.6372 115.782 108.274C75.2747 130.671 37.1918 154.542 3.21948 186.063"
                          stroke="#2E2828"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M2.34799 186.852C4.95724 187.268 7.5665 187.684 10.1757 188.1"
                          stroke="#2E2828"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M1.53258 186.597C1.87576 184.065 2.21894 181.532 2.56213 179"
                          stroke="#2E2828"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </Col>
                  </Col>
                  <Col
                    lg={12}
                    className="d-flex flex-row align-items-center justify-content-end"
                  >
                    <p className="label label-hide" title={titleLabel}>
                      {" "}
                      label hide{" "}
                    </p>
                    <svg
                      className="svg-label4 svg"
                      id="svg-label4-2"
                      width="299"
                      height="210"
                      viewBox="0 0 299 210"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M296.807 116.978C253.583 87.3745 207.227 82.4807 155.436 81.5207C104.196 80.5709 54.4631 82.3775 4.43742 93.7949"
                        stroke="#2E2828"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M1.99999 93.5435C4.27172 95.3868 6.54345 97.2301 8.81518 99.0734"
                        stroke="#2E2828"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M1.81117 93.5337C4.20335 92.0225 6.59553 90.5112 8.98771 89"
                        stroke="#2E2828"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </Col>
                  <Col>
                    <p className="label label-hide" title={titleLabel}>
                      {" "}
                      label hide{" "}
                    </p>
                    <svg
                      className="svg-label5 svg"
                      id="svg-label5-2"
                      width="300"
                      height="264"
                      viewBox="0 0 350 264"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M2 158C54.4898 116.065 109.064 106.623 169.748 102.037C229.787 97.5008 287.885 96.7078 345.71 108.612"
                        stroke="#2E2828"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M347.183 108.894C344.406 111.478 341.629 114.063 338.851 116.647"
                        stroke="#2E2828"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M346.739 108.754C344.856 105.904 342.973 103.053 341.09 100.202"
                        stroke="#2E2828"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </Col>
                  <Col
                    md={11}
                    lg={10}
                    className="d-flex flex-row justify-content-end"
                  >
                    <p
                      className="label"
                      data-images="c3-2"
                      data-label="label6-2"
                      title={titleLabel}
                    >
                      {" "}
                      corps caverneux{" "}
                    </p>
                    <svg
                      className="svg-label6-2 svg"
                      id="svg-label6-2"
                      width="211"
                      height="197"
                      viewBox="0 0 211 197"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M209 138C173.968 97.3895 141.169 83.7084 105.326 73.437C69.8639 63.2748 35.9304 56.4467 3.50278 60.2569"
                        stroke="#2E2828"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M1.77923 59.5441C3.67151 61.9982 5.56378 64.4522 7.45606 66.9063"
                        stroke="#2E2828"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M1.64743 59.4997C3.02656 58.2438 4.40569 56.9879 5.78481 55.7319"
                        stroke="#2E2828"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </Col>
                  <Col
                    md={11}
                    lg={10}
                    className="d-flex flex-row justify-content-start"
                  >
                    <p
                      className="label"
                      data-images="c2-2"
                      data-label="label7-2"
                      title={titleLabel}
                    >
                      {" "}
                      bulbe vestibulaire{" "}
                    </p>
                    <svg
                      className="svg-label7-2 svg"
                      id="svg-label7-2"
                      width="250"
                      height="210"
                      viewBox="0 0 298 210"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M2 116.814C45.2241 87.21 91.5796 82.3162 143.371 81.3562C194.611 80.4064 244.344 82.213 294.369 93.6304"
                        stroke="#2E2828"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M296.807 93.3789C294.535 95.2222 292.263 97.0655 289.992 98.9088"
                        stroke="#2E2828"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M296.996 93.3692C294.604 91.8579 292.211 90.3467 289.819 88.8354"
                        stroke="#2E2828"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </Col>
                  <Col lg={12} className="d-flex flex-row justify-content-end">
                    <p
                      className="label"
                      data-images="c3-2"
                      data-label="label8-2"
                      title={titleLabel}
                    >
                      {" "}
                      pilier clitoridien{" "}
                    </p>
                    <svg
                      className="svg-label8-2 svg"
                      id="svg-label8-2"
                      width="320"
                      height="342"
                      viewBox="0 0 321 342"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M319 247C257.101 251.429 206.478 228.643 152.565 198.562C99.2259 168.802 49.0578 137.119 4.22817 95.4062"
                        stroke="#2E2828"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M3.07829 94.3609C6.50023 93.8334 9.92217 93.3059 13.3441 92.7784"
                        stroke="#2E2828"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M3.40296 94.7199C3.87237 98.0533 4.34178 101.387 4.81119 104.72"
                        stroke="#2E2828"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </Col>
                  <Col
                    md={11}
                    lg={10}
                    className="d-flex flex-row justify-content-end"
                  >
                    <p className="label label-hide" title={titleLabel}>
                      {" "}
                      label hide{" "}
                    </p>
                    <svg
                      className="svg-label9 svg"
                      id="svg-label9-2"
                      width="192"
                      height="150"
                      viewBox="0 0 192 150"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M190 71.0001C160.406 55.818 130.692 56.4299 97.8326 59.9492C65.3231 63.4311 33.976 68.527 3.16743 79.6922"
                        stroke="#2E2828"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M2.38236 79.9663C3.9594 80.945 5.53644 81.9238 7.11347 82.9025"
                        stroke="#2E2828"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M1.92438 79.5794C2.85154 78.0335 3.7787 76.4877 4.70586 74.9418"
                        stroke="#2E2828"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </Col>
                </Row>
                {/* FIN SECTION SCREEN */}
                <Row className=" mt-5 mt-sm-0 mt-md-3">
                  <Col
                    className="d-flex justify-content-center justify-content-sm-start"
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                  >
                    <p className="last-row" id="anatom-vulve">
                      Anatomie d ’une vulve
                    </p>
                    <p className="last-row is-invisible" id="anatom-clito">
                      Anatomie d ’un clitoris
                    </p>

                    <svg
                      className="svg-last-row"
                      width="328"
                      height="84"
                      viewBox="0 0 328 84"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M249.812 6.04036C186.36 2.16041 120.319 -1.78711 58.1386 8.89995C40.4812 11.9348 -0.832472 19.6904 2.15367 33.7018C5.80184 50.8194 39.7875 64.3798 65.8758 70.3906C117.223 82.2209 175.484 85.3498 229.641 78.203C257.205 74.5657 291.927 68.7074 312.318 57.3635C326.085 49.7044 331.023 36.8317 319.818 27.534C306.999 16.8966 288.822 11.5573 266.367 9.47935C224.022 5.56066 180.509 7.32189 137.91 8.73175"
                        stroke="#EA5152"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </Col>
                </Row>
                {/*  DEBUT SECTION PHONE */}
                <Row id="row-images-phone">
                  <Col className="d-flex flex-row justify-content-center">
                    <img src={vulveComplette} alt={altDessinVulve} />
                  </Col>
                </Row>
                <Row id="row-label-phone">
                  <Col className=" d-flex flex-column align-items-center">
                    <p className="label" title={titleLabel}>
                      {" "}
                      pubis{" "}
                    </p>
                    <p className="label" title={titleLabel}>
                      {" "}
                      pubis{" "}
                    </p>
                    <p className="label" title={titleLabel}>
                      {" "}
                      capuchon{" "}
                    </p>
                    <p className="label" title={titleLabel}>
                      {" "}
                      urêtre{" "}
                    </p>
                    <p className="label" title={titleLabel}>
                      {" "}
                      grandes lèvres{" "}
                    </p>
                    <p className="label" title={titleLabel}>
                      {" "}
                      petites lèvres{" "}
                    </p>
                  </Col>
                  <Col className=" d-flex flex-column align-items-center">
                    <p className="label" title={titleLabel}>
                      {" "}
                      vagin{" "}
                    </p>
                    <p className="label" title={titleLabel}>
                      {" "}
                      orifice vaginal{" "}
                    </p>
                    <p className="label" title={titleLabel}>
                      {" "}
                      pubis{" "}
                    </p>
                    <p className="label" title={titleLabel}>
                      {" "}
                      périnée{" "}
                    </p>
                    <p className="label" title={titleLabel}>
                      {" "}
                      clitoris{" "}
                    </p>
                    <div className="clitoris-bonx-phone mt-3 d-flex flex-row">
                      <svg
                        id="svg-clitoris-phone"
                        width="36"
                        height="66"
                        viewBox="0 0 36 66"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M21.2092 1.89632C16.712 4.1825 12.3882 6.47358 8.78698 10.0842C6.21925 12.6587 3.99358 15.7433 2.67529 19.1494C0.760176 24.0976 0.114744 31.6748 4.3041 35.7475C4.93337 36.3592 5.78867 36.8668 6.741 37.2216M6.741 37.2216C8.94253 38.0419 11.6626 38.0459 13.3079 36.6335C14.449 35.6539 15.8349 34.0435 15.5713 32.3787C15.4364 31.527 14.3383 31.0983 13.5769 31.2441C11.9308 31.5595 10.2737 32.5061 9.02677 33.5952C7.81726 34.6517 7.10781 35.879 6.741 37.2216ZM6.741 37.2216C6.33586 38.7045 6.34872 40.328 6.56747 42.0171C7.56392 49.711 13.0606 54.7094 19.4196 58.4777C22.6299 60.3801 26.0285 61.8244 29.5404 63.063C31.3993 63.7185 33.2152 64.3436 35 65.1655M21.246 1.80243C20.9515 3.67002 20.5971 5.52187 20.2523 7.38047M21.2328 1.96142C19.7493 1.75075 18.2874 1.45221 16.8207 1.1532"
                          stroke="#CF393A"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                      <p className="label-clitoris-phone">
                        Découvre à quoi <br />
                        je ressemble
                      </p>
                    </div>
                  </Col>
                  <Col xs={12}>
                    <p className="p-call text-center">
                      Regarde sur ton écran d'ordinateur pour relier les mots
                      à l'illustration
                    </p>
                  </Col>
                </Row>
                {/*  FIN SECTION PHONE */}
              </Container>
            </div>
            <div
              id="slide"
              className="d-flex flex-column align-items-center justify-content-center"
            >
              <Container id="container2">
                <img
                  src={pictoTdf}
                  alt={altPictoTdf}
                  className="picto-tdf-abs top-70 translate-n200"
                />
                <Row>
                  <Col className="mb-n5 col-titre">
                    <p className="titre">Les règles, c’est quoi ?</p>
                    <svg
                      className=" mt-n5"
                      width="284"
                      height="13"
                      viewBox="0 0 284 13"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M1 1C58.2117 5.34842 115.505 3.16539 172.696 7.6321C209.569 10.5119 246.416 16.4961 283 6.2317"
                        stroke="white"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </Col>
                </Row>
                <Row>
                  <Col
                    lg={{ span: 5, offset: 0 }}
                    sm={{ span: 10, offset: 1 }}
                    md={{ span: 8, offset: 2 }}
                    className=" d-flex flex-column justify-content-center align-items-center"
                  >
                    <Carousel>
                      <Carousel.Item>
                        <p className="date">
                          Jour <span>1</span>
                        </p>
                        <p className="effet">
                          Début des règles : flux rouge arrive et devient
                          rapidement abondant, parfois épais. Des douleurs
                          peuvent aussi être présentes. La chaleur (bain ou
                          bouillotte), une alimentation seine, ou du sommeil
                          permettent de les soulager.
                        </p>
                        <div className="d-flex flex-row justify-content-center w-100">
                          <img src={cupJ1} alt={altCup} className="mt-2" />
                        </div>
                      </Carousel.Item>
                      <Carousel.Item>
                        <p className="date">
                          Jour <span>2</span>
                        </p>
                        <p className="effet">
                          Les saignements restent importants, et des crampes
                          peuvent être toujours vives. à retenir : l’abondance
                          des saignement correspond à l’épaisseur de l’endomêtre
                          qui peut varier d’un mois ou d’une femme à l’autre, à
                          rien d’autre.{" "}
                        </p>
                        <div className="d-flex flex-row justify-content-center w-100">
                          <img src={cupJ2} alt={altCup} className="mt-2" />
                        </div>
                      </Carousel.Item>
                      <Carousel.Item>
                        <p className="date">
                          Jour <span>3</span>
                        </p>
                        <p className="effet">
                          Le flux et les douleurs (s’il y en a) commencent à
                          diminuer. Il faut savoir que certaines femmes ont
                          toute leur vie des règles irrégulières, c’est dans
                          leur nature. D’autres peuvent avoir des règles qui
                          sautent toutes seules (stress, voyage...).
                        </p>
                        <div className="d-flex flex-row justify-content-center w-100">
                          <img src={cupJ3} alt={altCup} className="mt-2" />
                        </div>
                      </Carousel.Item>
                      <Carousel.Item>
                        <p className="date">
                          Jour <span>4</span>
                        </p>
                        <p className="effet">
                          Pertes réduitent, plus foncées. Il existe beaucoup de
                          mots, pas tous très élogieux, pour désigner les
                          saignements mensuels : règles, menstruations,
                          menstrues, époques, vilaines… Pendant cette période de
                          3 et 8 jours en moyenne, vous perdez en environ 65 ml
                          de sang.{" "}
                        </p>
                        <div className="d-flex flex-row justify-content-center w-100">
                          <img src={cupJ4} alt={altCup} className="mt-2" />
                        </div>
                      </Carousel.Item>
                      <Carousel.Item>
                        <p className="date">
                          Jour <span>5</span>
                        </p>
                        <p className="effet">
                          Fin des règles et ovulation qui se rapproche. Lors de
                          la ménopause (51 ans en moyenne en France), le cycle
                          reproductif s’arrête : il n’y a plus de menstruations.
                          Une femme saigne en moyenne 2400 jours (6 ans et demi)
                          de règles au cours de sa vie.
                        </p>
                        <div className="d-flex flex-row justify-content-center w-100">
                          <img src={cupJ5} alt={altCup} className="mt-2" />
                        </div>
                      </Carousel.Item>
                    </Carousel>
                  </Col>
                  <Col
                    lg={{ span: 6, offset: 1 }}
                    sm={{ span: 12 }}
                    md={{ span: 10, offset: 1 }}
                    className="col-texte"
                  >
                    <p>
                      Menstruation n.f. - Pertes de sang correspondant à
                      l'élimination spontanée de la muqueuse utérine qui se
                      forme à chaque ovulation pour recevoir un éventuel ovule
                      fécondé, dure en moyenne 5 jours.
                    </p>
                    <div className="box-tc">
                      <div className="box-titleCheck">
                        <div>
                          <svg
                            width="18"
                            height="20"
                            viewBox="0 0 16 18"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M1.22168 8.5884C1.89749 9.19478 2.42906 9.92853 2.97225 10.6525C3.64703 11.5518 4.31467 12.4595 4.91365 13.4117C5.59263 14.4911 6.20063 15.6143 6.81513 16.7308C6.89699 16.8795 7.03697 17.1489 7.21918 17.203C7.52228 17.293 7.733 16.6559 7.81407 16.4825C8.29539 15.4531 8.64195 14.3648 9.03109 13.2988C9.65104 11.6005 10.369 9.94931 11.0903 8.29242C12.1737 5.8037 13.2545 3.18245 14.8913 1"
                              stroke="#00732E"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        </div>
                        <p>Les régles c’est naturel</p>
                      </div>
                      <p>
                        En résumé : Les règles, c’est ce qui se passe quand une
                        femme rejette des tissus dont elle n’a plus besoin qui
                        proviennent de l’utérus, espace où le bébé (fœtus) se
                        développe dans le corps de la femme. Tous les mois
                        environ, l'endomètre épaissit pour se préparer à
                        accueillir un ovule fécondé si la femme tombe enceinte,
                        et si ce n’est pas le cas, cette muqueuse s’enlève et
                        s’écoule par le vagin : ce sont les règles.
                      </p>
                    </div>
                    <div className="box-tc">
                      <div className="box-titleCheck">
                        <div>
                          <svg
                            width="18"
                            height="20"
                            viewBox="0 0 16 18"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M1.22168 8.5884C1.89749 9.19478 2.42906 9.92853 2.97225 10.6525C3.64703 11.5518 4.31467 12.4595 4.91365 13.4117C5.59263 14.4911 6.20063 15.6143 6.81513 16.7308C6.89699 16.8795 7.03697 17.1489 7.21918 17.203C7.52228 17.293 7.733 16.6559 7.81407 16.4825C8.29539 15.4531 8.64195 14.3648 9.03109 13.2988C9.65104 11.6005 10.369 9.94931 11.0903 8.29242C12.1737 5.8037 13.2545 3.18245 14.8913 1"
                              stroke="#00732E"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        </div>
                        <p>Ça peut aussi être douloureux</p>
                      </div>
                      <p>
                        Les règles en elle-même ne font pas mal, mais certaines
                        filles ou femmes souffrent de crampes ou d’autres
                        symptômes pendant leurs règles, ce qui peut être
                        inconfortable. Ceci est dû aux hormones, produites par
                        ton corps pendant les règles, qui provoquent les
                        contractions de l’utérus afin d’évacuer l’endomètre
                        inutile.
                        <br /> Et le syndrôme pré-menstruel ? C’est l’ensemble
                        des symptômes physiques et émotionnels qui surviennent
                        habituellement de 2 à 7 jours avant les règles, et qui
                        affecte jusqu'à 80 % des femmes.
                      </p>
                    </div>
                  </Col>
                </Row>
              </Container>
            </div>
            <div
              id="slide"
              className="d-flex flex-column align-items-center justify-content-center"
            >
              <Container id="container3">
                <Row>
                  <Col className="col-titre">
                    <p className="titre">Les règles, comment ça se passe?</p>
                    <svg
                      className=" mt-n5"
                      width="403"
                      height="14"
                      viewBox="0 0 403 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M1 1.816C97.8678 -0.850639 194.78 3.47454 291.59 8.20685C328.371 10.0048 365.19 13 402 13"
                        stroke="white"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </Col>
                </Row>
                <Row className="d-flex justify-content-center">
                  <Col sm={12} lg={9} className="col1 p-5 ">
                    <p className="titre2">Le cycle de vingt-huit jours</p>
                    <div className=" d-flex flex-row box-phases justify-content-between mt-4">
                      <div className="box-text d-flex flex-column justify-content-start align-items-start">
                        <p className=" mb-1 titre3">RÈGLES</p>
                        <p className="def">
                          Période de <br /> saignement
                        </p>
                        <svg
                          id="svg-frise-1"
                          className="svg svg-frise-1"
                          width="68"
                          height="73"
                          viewBox="0 0 68 73"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M22.7836 5.69891C31.6763 10.6314 40.7978 16.927 44.3305 26.91C47.0301 34.5389 48.3115 43.2746 46.929 51.3131C45.8987 57.3037 44.4795 63.9467 42.2084 69.6354"
                            stroke="#EA5152"
                            strokeWidth="3"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M42.1614 69.7993C43.1121 69.2112 44.0634 68.624 45.0145 68.0367"
                            stroke="#EA5152"
                            strokeWidth="3"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M42.2664 70.0435C41.8587 68.8745 41.5159 67.7667 40.9371 66.6722"
                            stroke="#EA5152"
                            strokeWidth="3"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </div>
                      <div className="box-text d-flex flex-column justify-content-start align-items-start">
                        <p className=" mb-1 titre3"> PHASE FOLLICULAIRE</p>
                        <p className="def">
                          Les follicules en croissance <br /> produisent des
                          oestrogènes
                        </p>
                        <svg
                          id="svg-frise-2"
                          className="svg svg-frise-2"
                          width="81"
                          height="78"
                          viewBox="0 0 81 78"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M23.8341 3.00582C22.5382 17.2282 27.4944 28.7371 34.0969 40.9672C40.6292 53.0671 47.614 64.4306 56.9085 74.5227"
                            stroke="#EA5152"
                            strokeWidth="3"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M57.1417 74.782C57.2777 73.9944 57.4137 73.2069 57.5498 72.4193"
                            stroke="#EA5152"
                            strokeWidth="3"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M57.2987 75.1099C56.6261 74.7619 55.9536 74.4139 55.281 74.0659"
                            stroke="#EA5152"
                            strokeWidth="3"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </div>
                      <div className="box-text d-flex flex-column justify-content-start align-items-start">
                        <p className=" mb-1 titre3">OVULATION</p>
                        <p className="def">
                          Période de <br /> fécondité
                        </p>
                        <svg
                          id="svg-frise-3"
                          className="svg svg-frise-3"
                          width="25"
                          height="37"
                          viewBox="0 0 25 37"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M3.82554 33.4997C9.63247 23.3774 15.0338 12.655 22.3464 3.5429"
                            stroke="#EA5152"
                            strokeWidth="3"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M3.9209 33.4496C4.54213 33.1812 5.16336 32.9127 5.78459 32.6443"
                            stroke="#EA5152"
                            strokeWidth="3"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M3.84705 33.4308C3.75439 32.8326 3.69976 32.2235 3.62917 31.6202"
                            stroke="#EA5152"
                            strokeWidth="3"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </div>
                      <div className="box-text d-flex flex-column justify-content-start align-items-start">
                        <p className=" mb-1 titre3">PHRASE LUTÉALE</p>
                        <p className="def">
                          Le corps jaune produit <br /> de la progestérone
                        </p>
                        <svg
                          id="svg-frise-4"
                          className="svg svg-frise-4"
                          width="51"
                          height="82"
                          viewBox="0 0 51 82"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M48.7052 8.00596C35.7417 14.2151 28.2198 24.5581 20.8869 36.7323C13.632 48.7769 7.23888 60.8414 3.08912 74.294"
                            stroke="#EA5152"
                            strokeWidth="3"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M2.97982 74.6345C3.72944 74.3476 4.47905 74.0607 5.22867 73.7738"
                            stroke="#EA5152"
                            strokeWidth="3"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M2.77317 74.9432C2.74226 74.1675 2.71134 73.3918 2.68042 72.6161"
                            stroke="#EA5152"
                            strokeWidth="3"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </div>
                    </div>
                    <img
                      src={frise}
                      alt={altFrise}
                      className="img-frise mt-n5"
                    />
                  </Col>
                </Row>
                <Row className="mt-3 col-picto">
                  <img src={draw5} alt={altDessinVulve} width="50px" />
                </Row>
              </Container>
            </div>
            <div
              id="slide"
              className="d-flex flex-column align-items-center justify-content-center"
            >
              <img
                src={draw6}
                alt={altPictoTdf}
                className="picto-tdf-abs align-self-end"
              />
              <Container
                id="container4"
                className="d-flex flex-column align-items-center"
              >
                <Row className=" align-self-start mb-5">
                  <Col className="col-titre">
                    <p className="titre">My Dear Vagina c’est aussi ...</p>
                    <svg
                      className="svg mt-n5 "
                      width="403"
                      height="14"
                      viewBox="0 0 403 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M1 1.816C97.8678 -0.850639 194.78 3.47454 291.59 8.20685C328.371 10.0048 365.19 13 402 13"
                        stroke="#000"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </Col>
                </Row>

                <Row
                  id="row-images-screen"
                  className=" d-flex justify-content-center "
                >
                  <Col sm={12} className=" d-flex justify-content-center">
                    <img src={mains} alt="" id="hand-img" />
                  </Col>
                </Row>

                <div className="d-flex w-100">

                  <Row
                    id="row-label-screen"
                    className="d-flex flex-column align-items-center justify-content-center mt-sm-5 p-0 mt-lg-n3"
                  >
                    <Col className="d-flex justify-content-left ml-5">
                      <p className="label"> excision </p>
                    </Col>
                    <Col className="d-flex justify-content-left"><p className="label"> inclusivité </p></Col>
                    <Col className="d-flex justify-content-left ml-5"> <p className="label"> sexualité </p></Col>
                    <Col className="d-flex justify-content-left"> <p className="label"> pornographie </p></Col>
                    <Col className="d-flex justify-content-left ml-5"> <p className="label"> infertilité </p></Col>
                  </Row>

                  <Row
                    id="row-label-screen"
                    className="d-flex flex-column align-items-center justify-content-center mt-sm-5 p-0 mt-lg-n3"
                  >
                    <Col className="d-flex justify-content-end mr-5">
                      <p className="label"> vaginisme </p>
                    </Col>
                    <Col className="d-flex justify-content-end">
                      <p className="label"> poils </p>
                    </Col>
                    <Col className="d-flex justify-content-end mr-5">
                      <p className="label"> masturbation </p>
                    </Col>
                    <Col className="d-flex justify-content-end">
                      <p className="label"> maladies </p>
                    </Col>
                    <Col className="newCol mr-5">
                      <p className="other"> Et bien d’autres ! </p>
                    </Col>
                  </Row>
                  {/* 
                  <Row
                  id="row-label-screen"
                  className="d-flex flex-row align-items-center justify-content-center mt-sm-5 p-0 mt-lg-n3"
                ><Col className="newCol">
                    <p className="label"> excision </p>
                    <p className="label"> inclusivité </p>
                    <p className="label"> sexualité </p>
                    <p className="label"> pornographie </p>
                    <p className="label"> infertilité </p>
                  </Col>
                  <Col className="newCol">
                    <p className="label"> vaginisme </p>
                    <p className="label"> poils </p>
                    <p className="label"> masturbation </p>
                    <p className="label"> maladies </p>
                    <p className="other"> Et bien d’autres ! </p>
                  </Col></Row> */}
                </div>
                {/*   
                  <Col
                    md={9}
                    lg={10}
                    className="d-flex flex-row justify-content-end"
                  >
                    <p className="label"> excision </p>
                  </Col>
                  <Col
                    lg={9}
                    className="d-flex flex-row align-items-center justify-content-start"
                  >
                    <p className="label"> inclusivité </p>
                  </Col>
                  <Col
                    sm={12}
                    md={12}
                    lg={12}
                    className="d-flex flex-row align-items-center justify-content-space-between mt-3"
                  >
                    <Col className="d-flex flex-row justify-content-start align-items-start">
                      <p className="label"> sexualité </p>
                    </Col>
                    <Col className="d-flex flex-row justify-content-end mr-5">
                      <p className="label"> pornographie </p>
                    </Col>
                  </Col>
                  <Col
                    sm={12}
                    md={12}
                    lg={12}
                    className="d-flex flex-row align-items-center justify-content-space-between mt-3"
                  >
                    <Col
                      lg={{ span: 5, offset: 1 }}
                      className="d-flex flex-row justify-content-start align-items-start"
                    >
                      <p className="label"> infertilité </p>
                    </Col>
                    <Col className="d-flex flex-row justify-content-end">
                      <p className="label"> vaginisme </p>
                    </Col>
                  </Col>
                  <Col
                    sm={12}
                    md={12}
                    lg={12}
                    className="d-flex flex-row align-items-center justify-content-start mt-3 mb-5"
                  >
                    <Col
                      lg={7}
                      className="d-flex flex-row justify-content-start align-items-start"
                    >
                      <p className="label"> poils </p>
                    </Col>
                    <Col
                      lg={4}
                      className="d-flex flex-row justify-content-end "
                    >
                      <p className="label"> masturbation </p>
                    </Col>
                  </Col>

                  <Col
                    sm={12}
                    md={12}
                    lg={12}
                    className="d-flex flex-row align-items-center justify-content-start mb-5"
                  >
                    <Col
                      lg={7}
                      className="d-flex flex-row justify-content-start align-items-start mb-3"
                    >
                      <p className="label"> maladies </p>
                    </Col>
                    <Col
                      lg={4}
                      className="d-flex flex-row justify-content-end  mb-3"
                    >
                      <p className="other"> Et bien d’autres ! </p>
                    </Col>
                  </Col>
 */}
                <Row id="row-label-phone" className="mb-5">
                  <Col className=" d-flex flex-column align-items-center">
                    <p className="label"> excision </p>
                    <p className="label"> sexualité </p>
                    <p className="label"> infertilité </p>
                    <p className="label"> poils </p>
                    <p className="label"> maladies </p>
                  </Col>
                  <Col className=" d-flex flex-column align-items-center">
                    <p className="label"> inclusivité </p>
                    <p className="label"> pornographie </p>
                    <p className="label"> vaginisme </p>
                    <p className="label"> masturbation </p>
                    <p className="other mt-3"> Et bien d’autres ! </p>
                  </Col>
                </Row>
                <Row id="row-images-phone">
                  <Col>
                    <img src={mains} alt="" />
                  </Col>
                </Row>
              </Container>
            </div>
            <div
              id="slide"
              className="d-flex flex-column align-items-center justify-content-center"
            >
              <img
                src={draw7}
                alt={altPictoTdf}
                className="picto-tdf-abs top-90 align-self-end"
              />
              <Container id="container5">
                <Row>
                  <Col
                    xs={{ span: 10, offset: 1 }}
                    sm={{ span: 10, offset: 1 }}
                    md={{ span: 8, offset: 2 }}
                    lg={{ span: 6, offset: 0 }}
                    className=" d-flex flex-column align-items-center justify-content-center mb-5 mb-sm-5 mb-md-5 mb-lg-0"
                  >
                    <Carousel>
                      <Carousel.Item>
                        <img
                          className="img-slide"
                          src={slide1}
                          alt={altImgSlide}
                        />
                      </Carousel.Item>
                      <Carousel.Item>
                        <img
                          className="img-slide"
                          src={slide2}
                          alt={altImgSlide}
                        />
                      </Carousel.Item>
                      <Carousel.Item>
                        <img
                          className="img-slide"
                          src={slide3}
                          alt={altImgSlide}
                        />
                      </Carousel.Item>
                      <Carousel.Item>
                        <img
                          className="img-slide"
                          src={slide4}
                          alt={altImgSlide}
                        />
                      </Carousel.Item>
                      <Carousel.Item>
                        <img
                          className="img-slide"
                          src={slide5}
                          alt={altImgSlide}
                        />
                      </Carousel.Item>
                    </Carousel>
                    <p className="under-carousel">Aperçu de l ‘expo</p>
                  </Col>
                  <Col
                    xs={{ span: 10, offset: 1 }}
                    sm={{ span: 10, offset: 1 }}
                    md={{ span: 8, offset: 2 }}
                    lg={{ span: 5, offset: 1 }}
                    className="coll-text d-flex flex-column align-items-center justify-content-center mt-5 mt-sm-5 mt-md-5 mt-lg-0"
                  >
                    <div className="d-flex flex-column align-items-center justify-content-end box mb-0 mt-3 mt-sm-5">
                      <h3 className="text-center">My Dear Vagina x Nana</h3>
                      <p className="text-center mt-3">
                        Viva la vulva est une exposition organisée en 2019 par
                        Nana France et My Dear Vagina. L'objectif est de briser
                        les tabous sur le sexe féminin et ainsi de libérer la
                        parole des femmes.
                      </p>
                    </div>
                  </Col>
                </Row>
              </Container>
            </div>
            <div
              id="slide"
              className="d-flex flex-column align-items-center justify-content-center"
            >
              <Container id="container6" className=" m-0 p-0">
                <img
                  id="img-last-container"
                  src={vivaLaVulva}
                  alt={altExposition}
                />
              </Container>
            </div>
          </div>
        </div>
      </main>
    );
  }
}

export default ConnaisToi;
