/* eslint-disable react/jsx-no-target-blank */
import React, { Component } from "react";
import { Helmet } from "react-helmet";
import $ from "jquery";
import { Container, Row, Col, Button } from "react-bootstrap";
import "../Style/Homepage.scss";

//import des images
import couverture from "../Images/couverture-my-dear-vagina.jpg";
import pictoTdf from "../Images/picto-svg-tdf.svg";
import vulveVerte from "../Images/vulve-verte.png";
import vulveRose from "../Images/vulve-rose.png";
import vulveMarron from "../Images/vulve.png";
import instaFeed from "../Images/feed-insta-extends.png";
import masque from "../Images/masque-vulve.png";
import draw6 from "../Images/draw6.png";
import draw7 from "../Images/draw7.svg";

const altDessin =
  "dessin d'une vulve réalisé par la fondatrice du compte Instagram '@mydearvagina' Laura Stromboni-Couzy'";
const altMasque = "portrait Laura Stromboni-Couzy";
const altPictoTdf = "clit";

class Homepage extends Component {
  componentDidMount() {
    $("#svg-link1").addClass("component-active");

    wrappersize();
    window.onresize = wrappersize;
    // gestion de la taille .wrapper selon image container 6
    function wrappersize() {
      var wrapperHeight = $(".wrapper").outerHeight();
      var coeff = "1.59998994";
      var nbContainer = "500vw";
      $(".wrapper").css(
        "width",
        "calc( " +
        nbContainer +
        " + (" +
        coeff +
        " * " +
        wrapperHeight +
        "px)) "
      );
    }
  }

  render() {
    return (
      <main id="main-Homepage">
        <Helmet>
          <title>My Dear Vagina - Le livre</title>
        </Helmet>
        <div className="outer-wrapper">
          <div className="wrapper">
            <div
              className="d-flex flex-column align-items-center justify-content-center"
              id="slide"
            >
              <img
                src={draw6}
                alt={altPictoTdf}
                className="picto-tdf-abs top-80 translate-50 align-self-start"
              />
              <Container id="container1">
                <Row>
                  <Col
                    xs={{ span: 10, offset: 1 }}
                    sm={{ span: 10, offset: 1 }}
                    md={{ span: 8, offset: 2 }}
                    lg={{ span: 5, offset: 0 }}
                    className="d-flex align-items-center justify-content-center"
                  >
                    <img src={couverture} alt={altDessin} width="85%" />
                  </Col>
                  <Col
                    xs={{ span: 10, offset: 1 }}
                    sm={{ span: 10, offset: 1 }}
                    md={{ span: 8, offset: 2 }}
                    lg={{ span: 6, offset: 1 }}
                    className="coll-text d-flex flex-column align-items-center justify-content-center mt-3 mt-sm-5 mt-md-5 mt-lg-0"
                  >
                    <div className="d-flex flex-column align-items-center justify-content-end box ">
                      <h1 className="text-center">
                        <strong>My Dear Vagina</strong>
                      </h1>
                      <h2 className="text-center">
                        <strong>
                          Le journal intime de <br />
                          365 vulves
                        </strong>
                      </h2>
                      <p className="text-center mt-3">
                        Ode à l’amour du corps humain, assumé & célébré par les
                        mises en scènes créatives. 365 représentations de vulves
                        réunies dans un seul livre de chevet qui lie plaisir des
                        yeux et apprentissage du corps.
                      </p>
                    </div>
                  </Col>
                </Row>
                <Row className="mt-5">
                  <Col className="d-flex justify-content-end" lg={11}>
                    <Button className="align-items-center">
                      <img
                        src={pictoTdf}
                        alt={altDessin}
                        width="30px"
                        className="mr-2"
                      />
                      <span><a href="https://tidd.ly/3jF4b3c" target="_blank">Je le veux !</a></span>
                    </Button>
                  </Col>
                </Row>
              </Container>
            </div>
            <div
              className="d-flex flex-column align-items-center justify-content-center slide"
              id="slide"
            >
              <img
                src={draw7}
                alt={altPictoTdf}
                className="picto-tdf-abs translate-20 align-self-start"
              />
              <Container id="container2">
                <Row>
                  <Col
                    xs={{ span: 10, offset: 1 }}
                    sm={{ span: 10, offset: 1 }}
                    md={{ span: 8, offset: 2 }}
                    lg={{ span: 6, offset: 0 }}
                    className="coll-text d-flex flex-column align-items-center justify-content-center"
                  >
                    <div className="d-flex flex-column align-items-center justify-content-start box ">
                      <h3 className="text-center mt-3">De quoi ça parle ?</h3>
                      <p className="text-center mt-3">
                        Le <strong>livre My Dear Vagina</strong> sous la forme
                        d’un journal intime éduque & décomplexe cette partie de
                        notre corps à travers le portraits de 365 femmes
                        (personnes à vulves) de tous âges, milieux, pays
                        différents. Bienveillant, drôle, et assumé, il a pour
                        but de montrer qu’au moins 50% des personnes sur Terre
                        partagent les mêmes problématiques, et qu’il n’y a
                        aucune honte à avoir.
                      </p>
                    </div>
                  </Col>
                  <Col
                    xs={{ span: 10, offset: 1 }}
                    sm={{ span: 10, offset: 1 }}
                    md={{ span: 8, offset: 2 }}
                    lg={{ span: 5, offset: 1 }}
                    className=" d-flex flex-rox align-items-center justify-content-center mt-sm-5"
                  >
                    <img src={vulveVerte} alt={altDessin} width="80%" />
                  </Col>
                </Row>
                <Row className="mt-5">
                  <Col
                    className="d-flex justify-content-end p-0"
                    xs={10}
                    sm={10}
                    md={10}
                    lg={6}
                  >
                    <Button className="align-items-center">
                      <img
                        src={pictoTdf}
                        alt={altDessin}
                        width="30px"
                        className="mr-2"
                      />
                      <span><a href="https://tidd.ly/3jF4b3c" target="_blank">Le commander !</a></span>
                    </Button>
                  </Col>
                </Row>
              </Container>
            </div>
            <div
              className="d-flex flex-column align-items-center justify-content-center"
              id="slide"
            >
              <img
                src={draw7}
                alt={altPictoTdf}
                className="picto-tdf-abs top-80 align-self-center"
              />
              <Container id="container3">
                <Row>
                  <Col
                    xs={{ span: 10, offset: 1 }}
                    sm={{ span: 10, offset: 1 }}
                    md={{ span: 8, offset: 2 }}
                    lg={{ span: 4, offset: 0 }}
                    className=" d-flex  align-items-center justify-content-center"
                  >
                    <img
                      src={vulveRose}
                      alt="dessin d'une vulve réalisé par la fondatrice du compte Instagram '@mydearvagina' Laura Stromboni-couzy' "
                      width="90%"
                    />
                  </Col>
                  <Col
                    xs={{ span: 10, offset: 1 }}
                    sm={{ span: 10, offset: 1 }}
                    md={{ span: 8, offset: 2 }}
                    lg={{ span: 6, offset: 1 }}
                    className="coll-text d-flex flex-column align-items-center justify-content-center mt-3 mt-sm-5 mt-md-5 mt-lg-0"
                  >
                    <div className="d-flex flex-column align-items-center justify-content-end box ">
                      <h3 className="text-center">
                        Dédié à la moitié de l’humanité
                      </h3>
                      <p className="text-center mt-3">
                        Le but est d’en faire un beau livre, utile, ludique,
                        avec des chiffres, des définitions, des anecdotes qui
                        décomplexent, qui témoignent, qui aident les personnes à
                        vulve à mieux s’accepter et à mieux accepter leur corps.
                      </p>
                    </div>
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col
                    className="d-flex justify-content-end"
                    xs={{ span: 10, offset: 1 }}
                    sm={{ span: 10, offset: 1 }}
                    md={{ span: 8, offset: 2 }}
                    lg={9}
                  >
                    <Button className="align-items-center">
                      <img
                        src={pictoTdf}
                        alt={altDessin}
                        width="30px"
                        className="mr-2"
                      />
                      <span><a href="https://tidd.ly/3jF4b3c" target="_blank">Je le veux !</a></span>
                    </Button>
                  </Col>
                </Row>
              </Container>
            </div>
            <div
              className="d-flex flex-column align-items-center justify-content-center"
              id="slide"
            >
              <img
                src={draw6}
                alt={altPictoTdf}
                className="picto-tdf-abs translate-20 align-self-center"
              />
              <Container id="container4">
                <Row>
                  <Col
                    xs={{ span: 10, offset: 1 }}
                    sm={{ span: 10, offset: 1 }}
                    md={{ span: 8, offset: 2 }}
                    lg={{ span: 6, offset: 0 }}
                    className="coll-text d-flex flex-column align-items-center justify-content-center scotch"
                  >
                    <div className="d-flex flex-column align-items-center justify-content-start box ">
                      <h3 className="text-center mt-3">
                        Rempli de témoignages
                      </h3>
                      <p className="text-center mt-3">
                        Médecins, sexologues, gynécologues, actrices,
                        chroniqueuses, chanteuses, peintres, podcasteuses,
                        artistes, tatoueuses, salariées, entrepreneuses, et
                        tellement plus encore, elles se confient à nous et
                        prouvent avec brio et délicatesse à quel point nous
                        sommes à la fois toutes différentes et nous partageons
                        les mêmes problématiques.
                      </p>
                    </div>
                  </Col>
                  <Col
                    xs={{ span: 10, offset: 1 }}
                    sm={{ span: 10, offset: 1 }}
                    md={{ span: 8, offset: 2 }}
                    lg={{ span: 6, offset: 0 }}
                    className=" d-flex flex-rox align-items-center justify-content-center mt-sm-5"
                  >
                    <img
                      src={vulveMarron}
                      alt="dessin d'une vulve réalisé par la fondatrice du compte Instagram '@mydearvagina' Laura Stromboni-couzy'"
                      width="80%"
                    />
                  </Col>
                </Row>
                <Row className="mt-2">
                  <Col
                    className="d-flex justify-content-end p-0"
                    xs={10}
                    sm={10}
                    md={10}
                    lg={6}
                  >
                    <Button className="align-items-center">
                      <img
                        src={pictoTdf}
                        alt={altDessin}
                        width="30px"
                        className="mr-2"
                      />
                      <a href="https://tidd.ly/3jF4b3c" target="_blank">Le commander !</a>
                    </Button>
                  </Col>
                </Row>
              </Container>
            </div>
            <div
              className="d-flex flex-column align-items-center justify-content-center"
              id="slide"
            >
              <img
                src={draw7}
                alt={altPictoTdf}
                className="picto-tdf-abs translate-n300 align-self-end"
              />
              <Container id="container5">
                <Row>
                  <Col
                    xs={{ span: 10, offset: 1 }}
                    sm={{ span: 10, offset: 1 }}
                    md={{ span: 8, offset: 2 }}
                    lg={{ span: 4, offset: 0 }}
                    className=" d-flex  align-items-center justify-content-center"
                  >
                    <img
                      src={instaFeed}
                      alt="dessin d'une vulve réalisé par la fondatrice du compte Instagram '@mydearvagina' Laura Stromboni-couzy' "
                      width="100%"
                    />
                  </Col>
                  <Col
                    xs={{ span: 10, offset: 1 }}
                    sm={{ span: 10, offset: 1 }}
                    md={{ span: 8, offset: 2 }}
                    lg={{ span: 6, offset: 1 }}
                    className="coll-text d-flex flex-column align-items-center justify-content-center mt-3 mt-sm-5 mt-md-5 mt-lg-0"
                  >
                    <div className="d-flex flex-column align-items-center justify-content-end box ">
                      <h3 className="text-center">
                        Une vulve par <br /> jour pendant un an
                      </h3>
                      <p className="text-center mt-3">
                        Le projet <strong>My Dear Vagina</strong>, est né en
                        mars 2018 sur Instagram sur le compte (at)
                        <strong>mydearvagina</strong>, en un recueil de visuels
                        artistiques, curations ou créations, ayant pour seul
                        point commun de faire voyager nos vulves à travers le
                        monde.
                      </p>
                    </div>
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col
                    className="d-flex justify-content-end"
                    xs={{ span: 10, offset: 1 }}
                    sm={{ span: 10, offset: 1 }}
                    md={{ span: 8, offset: 2 }}
                    lg={9}
                  >
                    <Button className="align-items-center">
                      <img
                        src={pictoTdf}
                        alt={altDessin}
                        width="30px"
                        className="mr-2"
                      />
                      <span><a href="https://tidd.ly/3jF4b3c" target="_blank">Je le veux !</a></span>
                    </Button>
                  </Col>
                </Row>
              </Container>
            </div>
            <div
              className=" slide-last d-flex flex-column align-items-center justify-content-center"
              id="slide"
            >
              <Container id="container6" className=" m-0 p-0">
                <img src={masque} alt={altMasque} />
              </Container>
            </div>
          </div>
        </div>
      </main>
    );
  }
}

export default Homepage;
